@tailwind base;
@tailwind components;
@tailwind utilities;
/* Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}


.scan section div {
  /* background-color: black; */
  /* padding-top: 0% !important; */
  height: 400px;
  padding: 5px !important;

}

.scan section div #video {
  padding: 5px;
  background-color: white;
}

.scan section {
  /* padding-top: 50%; */

}

#video {
  --size: 32px;
  /* width: 100px;
  height: 100px; */
  /* padding: 10px; */
  background:
    linear-gradient(#F76B5B var(--size), transparent 0 calc(100% - var(--size)), #F76B5B 0) 0 0 / 4px 100%,
    linear-gradient(#F76B5B var(--size), transparent 0 calc(100% - var(--size)), #F76B5B 0) 100% 0 / 4px 100%,
    linear-gradient(to right, #F76B5B var(--size), transparent 0 calc(100% - var(--size)), #F76B5B 0) 0 0 / 100% 4px,
    linear-gradient(to right, #F76B5B var(--size), transparent 0 calc(100% - var(--size)), #F76B5B 0) 0 100% / 100% 4px;
  background-repeat: no-repeat;
}

.qr-video {
  /* -32px: 32px; */
  /* width: 100px;
  height: 100px; */
  /* padding: 10px; */
  background:
    linear-gradient(#F76B5B var(-32px), transparent 0 calc(100% - var(-32px)), #F76B5B 0) 0 0 / 4px 100%,
    linear-gradient(#F76B5B var(-32px), transparent 0 calc(100% - var(-32px)), #F76B5B 0) 100% 0 / 4px 100%,
    linear-gradient(to right, #F76B5B var(-32px), transparent 0 calc(100% - var(-32px)), #F76B5B 0) 0 0 / 100% 4px,
    linear-gradient(to right, #F76B5B var(-32px), transparent 0 calc(100% - var(-32px)), #F76B5B 0) 0 100% / 100% 4px;
  background-repeat: no-repeat;
}


@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #F76B5B;
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}